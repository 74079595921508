import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings, changeEdgeFilling,
  updateLastPath, addPathToLastEdgeFilling, removeLastPath
} from './Actions';
import DataField from '../../../../src/dataField/DataField.js';
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { selectTruck } from '../../../../src/truckSelect/Actions';
import ContractSelect from '../../../../src/contractSelect/ContractSelect.js';
import ConstructionSiteSelect from '../../../../src/constructionSiteSelect/ConstructionSiteSelect.js';
import {
  fetch, integerValue, floatValue, Socket, timer, toETRSTM35FIN, testIsValidRoadInfo, paddedNumber,
  toRadians, toDegrees, toWGS84, stateValueParser, ROAD_URL, calculateDistance,
  calculateRoadDistance, getRoadData
} from '../utils.js';
import { MapView, RoadwaySelect, DirectionSelect, AccuracyFixer } from './Components';
import ChangeEdgeFillingView from './Components';
import './EdgeFilling.css';


const View = props => {
  if (props.view === 0) {
    return (
      <div>
        <BasicInfo changeState={props.changeState} height={props.height}
          width={props.width} mass={props.mass}
          varietyWeight={props.varietyWeight}
          variety={props.variety} pit={props.pit}
          pits={props.pits} goAddingPit={props.goAddingPit}
          lastHeight={props.lastHeight} lastWidth={props.lastWidth}
          lastVarietyWeight={props.lastVarietyWeight}
          lastVariety={props.lastVariety} lastPit={props.lastPit} store={props.store} />
      </div>
    );
  }
  else if (props.view === 1) {
    return (
      <div>
        <MassInput changeState={props.changeState}
          roadway={props.roadway}
          direction={props.direction}
          startRoadPart={props.startRoadPart}
          startRoadPole={props.startRoadPole}
          location_road_number={props.location_road_number}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          newEdgeFilling={props.newEdgeFilling}
          useLocation={props.useLocation}
          notice={props.notice}
          dailyCount={props.dailyCount} lastPart={props.lastPart}
          lastPole={props.lastPole}
          edgeFillings={props.edgeFillings}
          autoGPS={props.autoGPS}
          endEdgeFilling={props.endEdgeFilling}
          creatingEdgeFilling={props.creatingEdgeFilling}
          disableSubmit={props.disableSubmit}
          lastDirection={props.lastDirection}
          lastRoadway={props.lastRoadway}
          newPath={props.newPath}
          disableNewPath={props.disableNewPath} />
      </div>
    );
  }
  else if (props.view === 2) {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} edgeFillings={props.edgeFillings}
          target={props.selectedConstructionSite}
          dailyMass={props.dailyMass}
          dailyDistance={props.dailyDistance}
          wholeDistance={props.wholeDistance}
          wholeMass={props.wholeMass}
          loading={props.loadingEdgeFillings}
          removeLastPath={props.removeLastPath}
          removeEdgeFilling={props.confirmRemoveEdgeFilling}
          removeLocalEdgeFilling={props.confirmRemoveLocalEdgeFilling}
          roadNumber={props.location_road_number} roadPart={props.location_road_part}
          roadPole={props.location_road_pole} accuracy={props.accuracy}
          time={props.locationTime} changeEdgeFilling={props.changeEdgeFilling} />
      </div>
    );
  }
  else {
    return (
      <div>
        <MapView edgeFillings={props.edgeFillings} yourLatitude={props.latitude} yourLongitude={props.longitude}
          roadNumber={props.roadNumber} roadPart={props.roadPart}
          roadPole={props.roadPole} accuracy={props.accuracy}
          showMessage={props.showMessage} site={props.selectedConstructionSite}
          mapPaths={props.mapPaths} mapZoom={props.mapZoom}
          mapPosition={props.mapPosition} organizationId={props.organizationId} />
      </div>
    );
  }
};

const BasicInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Perustiedot</h4>
      </legend>
      <div className='row'>
        <div className='column'>
          <ContractSelect required store={props.store} />
        </div>
        <div className='column'>
          <ConstructionSiteSelect required store={props.store} />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <label htmlFor='width'>Leveys (mm)</label>
          {props.lastWidth != null ? <div>{'Edellinen: ' + props.lastWidth + ' mm'}<br /></div> : null}
          <input id='width' type='tel'
            onChange={props.changeState.bind(this, 'width', 'float', 0.0)}
            value={props.width} required />
        </div>
        <div className="column">
          <label htmlFor='height'>Korkeus (mm)</label>
          {props.lastHeight != null ? <div>{'Edellinen: ' + props.lastHeight}<br /></div> : null}
          <input id='height' type='tel'
            onChange={props.changeState.bind(this, 'height', 'float', 0.0)}
            value={props.height} required />
        </div>
        <div className='column'>
          <label htmlFor='mass'>Kuorma (Tonnit) </label>
          <input id='mass' type='tel'
            value={props.mass}
            onChange={props.changeState.bind(this, 'truckMass', 'float', 0.0)}
            required />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <label htmlFor='variety'>Lajike</label>
          {props.lastVariety ? <div>{'Edellinen: ' + props.lastVariety}<br /></div> : null}
          <select id='variety'
            onChange={props.changeState.bind(this, 'variety', 'string', '')}
            value={props.variety}>
            <option value=''>Valitse lajike</option>
            <option value='0-16'>0-16</option>
            <option value='0-32'>0-32</option>
            <option value='0-45'>0-45</option>
            <option value='0-55'>0-55</option>
            <option value='0-63'>0-63</option>
          </select>
        </div>
        <div className="column">
          <label htmlFor='varietyWeight'>Lajike ominaispaino</label>
          {props.lastVarietyWeight != null ? <div>{'Edellinen: ' + props.lastVarietyWeight}<br /></div> : null}
          <input id='varietyWeight' type='tel'
            onChange={props.changeState.bind(this, 'varietyWeight', 'float', 0.0)}
            value={props.varietyWeight} required />
        </div>
        <div className="column">
          <label htmlFor='pit'>Monttu</label>
          {props.lastPit ? <div>{'Edellinen: ' + props.lastPit}<br /></div> : null}
          <select id='pit' className='float-left'
            onChange={props.changeState.bind(this, 'pit', 'string', '')}
            value={props.pit}>
            <option value=''>Valitse monttu</option>
            {
              props.pits.map((pit, index) => (
                <option key={index} value={pit}>
                  {pit}
                </option>
              ))
            }
          </select>
          <button className='button-outline' onClick={props.goAddingPit}>
            Uusi monttu
          </button>
        </div>
      </div>
    </fieldset>
  );
};

const CurrentData = props => {
  return (
    <div>
      Päivän matka: <strong>{props.dailyDistance || '-'}</strong> m
      <br />
      Päivän käytetty massa: <strong>{props.dailyMass || '-'}</strong> tonnia
      <br />
      Kohteen matka: <strong>{props.wholeDistance || '-'}</strong> m
      <br />
      Kohteen käytetty massa: <strong>{props.wholeMass || '-'}</strong> tonnia
    </div>
  );
}

const MassInput = props => {
  let road = props.road;

  if (road == null) {
    road = props.location_road_number;
  }

  return (
    <fieldset>
      <legend>
        <h4>Levitystiedot</h4>
      </legend>
      <div>
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='road'>Tie</label>
            <input id='road' type='text' value={road || ''} readOnly />
          </div>
          <div className='column'>
            <label htmlFor='roadPart'>Tieosa</label>
            Edellinen: {props.lastPart || '-'}
            <input id='roadPart' type='tel'
              value={props.startRoadPart}
              onChange={props.changeState.bind(this, 'road_part', 'integer', 0)}
              readOnly={props.autoGPS}
              required />
          </div>
          <div className='column'>
            <label htmlFor='pole'>Paalu</label>
            Edellinen: {props.lastPole || '-'}
            <input id='pole' type='tel'
              value={props.startRoadPole}
              onChange={props.changeState.bind(this, 'road_pole', 'integer', 0)}
              readOnly={props.autoGPS}
              required />
            <label className='checkbox'>
              Automattinen GPS
              <input type='checkbox'
                onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)}
                checked={props.autoGPS} />
            </label>
          </div>
        </div>
        <div className='row borders'>
          <div className='column'>
            <DirectionSelect onChange={props.changeState} direction={props.direction}
              last={props.lastDirection} />
          </div>
          <div className='column'>
            <RoadwaySelect onChange={props.changeState} roadway={props.roadway}
              last={props.lastRoadway} />
          </div>
        </div>
        <label htmlFor='notice'>Huomiot</label>
        <input id='notice' type='text' value={props.notice || ''}
          onChange={props.changeState.bind(this, 'notice', 'string', '')} />
        <div className='center row'>
          <div className='column'>
            {props.disableSubmit ? <div className='loader float-left' /> :
              (props.creatingEdgeFilling ?
                <button onClick={props.endEdgeFilling}>Lopeta levitys</button>
                :
                <button onClick={props.newEdgeFilling}>Aloita levitys</button>)
            }
          </div>
          <div className='column'>
            {props.disableNewPath ?
              <div className='loader' />
              :
              <button onClick={props.newPath} className='button-outline'>
                Jatka edellistä kuormaa
              </button>
            }
          </div>
        </div>
      </div>
    </fieldset>
  );
}

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <FiveLastEdgeFillings edgeFillings={props.edgeFillings} target={props.target}
        loading={props.loading} removeEdgeFilling={props.removeEdgeFilling}
        removeLocalEdgeFilling={props.removeLocalEdgeFilling}
        changeEdgeFilling={props.changeEdgeFilling}
        removeLastPath={props.removeLastPath} />
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
            roadPole={props.roadPole} loading={props.loading}
            accuracy={props.accuracy} time={props.time} />
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass}
            dailyDistance={props.dailyDistance}
            wholeDistance={props.wholeDistance}
            wholeMass={props.wholeMass} />
        </div>
      </div>
    </fieldset>
  );
};

const FiveLastEdgeFillings = props => {
  if (props.target == null) return null;
  if (props.edgeFillings.size === 0) return <p>Ei yhtään lisäystä</p>;
  if (props.loading) return <div className="loader"></div>;

  return (
    <div>
      <h4>5 viimeisintä reunantäyttöä</h4>
      {props.edgeFillings.splice(0, props.edgeFillings.size - 5).map(edgeFilling => {
        const date = new Date(edgeFilling.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        let header = [];
        let roadInfo = '(Ei paikka tietoja)';

        if (edgeFilling.get('paths').size === 1) {
          roadInfo = edgeFilling.get('paths').first().get('start_part') + ' / ' + edgeFilling.get('paths').first().get('start_distance') +
            (edgeFilling.get('paths').first().get('end_part') ? (' - ' + edgeFilling.get('paths').first().get('end_part') + ' / ' +
              edgeFilling.get('paths').first().get('end_distance')) : '');
        }
        else if (edgeFilling.get('paths').size > 1) {
          roadInfo = edgeFilling.get('paths').size + ' levitystä';
        }

        header.push(<span key={edgeFilling.get('id')}>{
          time + ' | ' + (edgeFilling.get('distance') ? (edgeFilling.get('distance') + ' m | ') : '') +
          roadInfo + ' | Massa: ' + (edgeFilling.get('mass') + ' tonnia' || '-')}
        </span>);
        let data = {
          'Huomiot': edgeFilling.get('notice') || '-',
          'Leveys (mm)': edgeFilling.get('width') || '-',
          'Korkeus (mm)': edgeFilling.get('height') || '-',
          'Lajike': edgeFilling.get('variety') || '-',
          'Lajike ominaispaino': edgeFilling.get('variety_weight') || '-',
          'Monttu': edgeFilling.get('pit') || '-'
        }

        if (edgeFilling.get('truck_mass')) {
          data['Rekka'] = edgeFilling.get('truck').get('register_number');
          data['Kuorman koko'] = edgeFilling.get('truck_mass');
        }

        if (edgeFilling.get('not_saved')) {
          header.push(<span key={edgeFilling.get('id') + '-not_saved'}>
            {edgeFilling.get('not_saved') ? ' (Ei palvelimella)' : ''}
          </span>);
        }

        return (
          <div className='datafield' key={edgeFilling.get('id')}>
            <DataField header={header} data={data} class={edgeFilling.get('not_saved') ? 'yellow' : null}>
              <br />
              {edgeFilling.get('paths').map((path, index) => (
                <p key={index}>
                  {edgeFilling.get('paths').size > 1 ?
                    <span>
                      <strong>{index + 1 + '. '}</strong>
                      {path.get('start_part') + ' / ' + path.get('start_distance') +
                        ' - ' + (path.get('end_part') ? (path.get('end_part') + ' / ' + path.get('end_distance')) : '')}
                    </span>
                    : null}
                  <strong>Ajorata:</strong> {' ' + path.get('roadway') + ' '}
                  <strong>Suunta:</strong> {' ' + path.get('direction') + ' '}
                  {path.get('length') != null ? <span><strong>Pituus:</strong>{' ' + path.get('length') + ' m '}</span>
                    : null}
                </p>
              ))
              }
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeEdgeFilling.bind(this, edgeFilling)}>
                    Muokkaa
                  </button>
                </div>
                {edgeFilling.get('paths').size > 1 ?
                  <div className='column'>
                    <button className='button-outline' onClick={props.removeLastPath.bind(this, edgeFilling)}>
                      Poista viimeisin levitys
                    </button>
                  </div>
                  : null
                }
                <div className='column'>
                  <button className='button-outline' onClick={edgeFilling.get('not_saved') ?
                    props.removeLocalEdgeFilling.bind(this, edgeFilling.get('id')) :
                    props.removeEdgeFilling.bind(this, edgeFilling.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            Nykyinen sijainti
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {'Tie: '}
            {props.roadNumber || '-'}
          </td>
          <td>
            {'Tieosa: '}
            {props.roadPart || '-'}
          </td>
          <td>
            {'Paalu: '}
            {props.roadPole || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {'Päivitetty: '}
            {props.time || '-'}
          </td>
          <td>
            {'Tarkkuus: '}
            <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
          </td>
        </tr>
      </tbody>
    </table>
  );
}


export const EdgeFillingNew = (properties) => {
  const props = properties;

  let watchID = null;
  let socket = useRef(null);
  let removingEdgeFilling;
  let removingPathByEdgeFilling;

  const [direction, setDirection] = useState(integerValue(localStorage.direction, 1));
  const [roadway, setRoadway] = useState(integerValue(localStorage.roadway, 0));
  const [height, setHeight] = useState(floatValue(localStorage.height, 0));
  const [width, setWidth] = useState(floatValue(localStorage.width, 250));
  const [variety, setVariety] = useState(localStorage.variety || '0-16');
  const [varietyWeight, setVarietyWeight] = useState(floatValue(localStorage.varietyWeight, 0));
  const [pit, setPit] = useState(localStorage.pit || '');
  const [road_part, setRoad_part] = useState(integerValue(localStorage.road_part, 0));
  const [road_pole, setRoad_pole] = useState(integerValue(localStorage.road_pole, 0));
  const [truckMass, setTruckMass] = useState(floatValue(localStorage.truckMass, 0));
  const [notice, setNotice] = useState(localStorage.notice || '');
  const [dailyCount, setDailyCount] = useState(0);
  const [dailyMass, setDailyMass] = useState(0);
  const [viewState, setViewState] = useState(0);
  const [autoGPS, setAutoGPS] = useState(localStorage.autoGPS === 'false' ? false : true);
  const [creatingEdgeFilling, setCreatingEdgeFilling] = useState(localStorage.creatingEdgeFilling ? JSON.parse(localStorage.creatingEdgeFilling) : null);
  const [confirmed, setConfirmed] = useState(false);
  const [traveledDistance, setTraveledDistance] = useState(0);
  const [currentMassUsage, setCurrentMassUsage] = useState(0);
  const [pits, setPits] = useState(localStorage.pits ? JSON.parse(localStorage.pits) : []);
  const [paths, setPaths] = useState({});
  const [mapPaths, setMapPaths] = useState([]);
  const [externalGPS, setExternalGPS] = useState(null);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [accuracy, setAccuracy] = useState();
  const [locationTime, setLocationTime] = useState();
  const [locationErrorState, setLocationErrorState] = useState();
  const [location_road_number, setLocation_road_number] = useState();
  const [location_road_part, setLocation_road_part] = useState();
  const [location_road_pole, setLocation_road_pole] = useState();
  const [road_distance, setRoad_distance] = useState();
  const [location_road_distance, setLocation_road_distance] = useState();
  const [showAccuracyFixer, setShowAccuracyFixer] = useState(null);
  const [callback, setCallback] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState();
  const [lastPath, setLastPath] = useState();
  const [loadingEdgeFillings, setLoadingEdgeFillings] = useState();
  const [changingEdgeFilling, setChangingEdgeFilling] = useState();
  const [changingMass, setChangingMass] = useState();
  const [dailyDistance, setDailyDistance] = useState();
  const [wholeDistance, setWholeDistance] = useState();
  const [wholeMass, setWholeMass] = useState();
  const [accuracySubmit, setAccuracySubmit] = useState();
  const [lastPart, setLastPart] = useState();
  const [lastPole, setLastPole] = useState();
  const [lastWidth, setLastWidth] = useState();
  const [lastHeight, setLastHeight] = useState();
  const [lastVariety, setLastVariety] = useState();
  const [lastVarietyWeight, setLastVarietyWeight] = useState();
  const [lastPit, setLastPit] = useState();
  const [lastDirection, setLastDirection] = useState();
  const [lastRoadway, setLastRoadway] = useState();
  const [mapZoom, setMapZoom] = useState();
  const [mapPosition, setMapPosition] = useState();
  const [disableNewPath, setDisableNewPath] = useState();

  useEffect(() => {
    if (localStorage['login'] == null) {
      return;
    }

    if (socket.current == null && typeof (WebSocket) !== 'undefined') {
      socket.current = Socket('/data/edgefilling');
    }

    watchID = navigator.geolocation.watchPosition(setLocation, locationError,
      { enableHighAccuracy: true });

    return () => {
      navigator.geolocation.clearWatch(watchID);
      if (socket.current != null) socket.current.close();
    };
  }, [])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      if (typeof (Storage) !== "undefined") {
        getEdgeFillings(props.selectedConstructionSite.get('id'));
      }

      socket.current.onmessage = async function (e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = props.edgeFillings.find(mass => mass.paths_id === data.model.paths_id);
            if (!exist) {
              return;
            }
          }

          getMacadams(props.selectedConstructionSite.get('id'), true);
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }

            if (data.model.paths_id) {
              data.model.paths = await getPaths(data.model.paths_id);
            }

            props.changeEdgeFilling(data.model);
          }
          else {
            const exist = props.macadams.find(mass => mass.paths_id === data.model.paths_id);
            if (exist) {
              getEdgeFillings(props.selectedConstructionSite.get('id'), true);
            }
          }
        }
        else if (data['operation'] === 'delete') {
          props.removeEdgeFilling(data.model);
        }
      }.bind(this);
    }

    if (props.selectedConstructionSite == null && viewState !== 0) {
      setViewState(0);
    }
  }, [props.selectedConstructionSite, viewState])

  useEffect(() => {
    if (autoGPS) {
      useLocation(true);
    }
  }, [autoGPS])

  useEffect(() => {
    getLastEdgeFillingData(props.edgeFillings, creatingEdgeFilling);
  }, [creatingEdgeFilling])

  useEffect(() => {
    getLastEdgeFillingData(props.edgeFillings, creatingEdgeFilling);
    getDailyData(props.edgeFillings);
    getMapPaths(props.edgeFillings, props.selectedConstructionSite ?
      props.selectedConstructionSite.get('id') : null);
  }, [props.edgeFillings])

  useEffect(() => {
    if (props.selectedContract == null || props.selectedConstructionSite == null) {
      props.clearEdgeFillings();
      return;
    }
  }, [props.selectedContract, props.selectedConstructionSite])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      getEdgeFillings(props.selectedConstructionSite.get('id'));
    }
  }, [props.selectedConstructionSite])

  const changeState = (propertyName, type, defaultValue, event) => {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    if (propertyName === 'width') {
      setWidth(value);
    }
    if (propertyName === 'height') {
      setHeight(value);
    }
    if (propertyName === 'truckMass') {
      setTruckMass(value);
    }
    if (propertyName === 'variety') {
      setVariety(value);
    }
    if (propertyName === 'varietyWeight') {
      setVarietyWeight(value);
    }
    if (propertyName === 'pit') {
      setPit(value);
    }
    if (propertyName === 'road_part') {
      setRoad_part(value);
    }
    if (propertyName === 'road_pole') {
      setRoad_pole(value);
    }
    if (propertyName === 'autoGPS') {
      setAutoGPS(value);
    }
    if (propertyName === 'notice') {
      setNotice(value);
    }
    if (propertyName === 'roadway') {
      setRoadway(value);
    }

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  const setLocation = async (position) => {
    let latitude = null;
    let longitude = null;
    let accuracy = null;

    if (position.coords) {
      if (externalGPS) {
        return;
      }

      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
    }

    const time = new Date();

    setLatitude(latitude);
    setLongitude(longitude);
    setAccuracy(accuracy);
    setLocationTime(paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()));

    let road;

    if (props.selectedConstructionSite != null) {
      road = props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      setLocationErrorState(false);
      setLocation_road_number(data.road);
      setLocation_road_part(data.part);
      setLocation_road_pole(data.distance);
    }
    else {
      const data = await getRoadData(converted.y, converted.x, accuracy);

      if (data != null) {
        setLocationErrorState(false);
        setLocation_road_number(data.road);
        setLocation_road_part(data.part);
        setLocation_road_pole(data.distance);
      }
      else {
        setLocationErrorState(true);
        setLocation_road_number(null);
        setLocation_road_part(null);
        setLocation_road_pole(null);
      }
    }
  }

  useEffect(() => {
    if (locationErrorState === false) {
      useLocation(autoGPS);
    }
  }, [locationErrorState, location_road_number, location_road_part, location_road_pole])

  const useLocation = async (autoGPS) => {
    const roadNumber = location_road_number;

    if (props.selectedConstructionSite &&
      props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    if (road_part < location_road_part ||
      (road_part === location_road_part &&
        road_distance < location_road_distance)) {
      setDirection(1);
    }
    else {
      setDirection(2);
    }

    const creatingEdgeFilling = creatingEdgeFilling;

    if (creatingEdgeFilling) {
      let traveledDistance = await calculateRoadDistance(roadNumber, creatingEdgeFilling.roadPart, creatingEdgeFilling.roadDistance,
        roadNumber, location_road_part, location_road_pole);

      if (traveledDistance == null) {
        traveledDistance = await calculateDistance(latitude, longitude,
          creatingEdgeFilling.latitude, creatingEdgeFilling.longitude);
      }

      const currentMassUsage = (creatingEdgeFilling.width / 1000) * (creatingEdgeFilling.height / 1000) *
        creatingEdgeFilling.variety_weight * traveledDistance / 1000;
      setTraveledDistance(traveledDistance);
      setCurrentMassUsage(Math.round(currentMassUsage * 100) / 100);
    }

    if (!autoGPS) return;

    setRoad_part(location_road_part || '');
    setRoad_pole(location_road_pole || '');
  }

  useEffect(() => {
    if (showAccuracyFixer && accuracy < 20) {
      accuracySubmit();
    }
    localStorage.road_part = road_part;
    localStorage.road_pole = road_pole;
  }, [road_part, road_pole])

  const locationError = (err) => {
    props.showMessage('Virhe', 'ERROR(' + err.code + '): ' + err.message, 'Error');
  }

  const saveEdgeFilling = (edgeFilling, path) => {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage['savedEdgeFillings'] == null) {
        localStorage['savedEdgeFillings'] = JSON.stringify([]);
      }

      edgeFilling.id = Date.now();
      edgeFilling.not_saved = true;
      edgeFilling.paths = [path];

      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      edgeFillings.push(edgeFilling);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      props.showMessage('Virhe', 'Mursketta ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  const sendSavedEdgeFillings = async () => {
    let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
    let newEdgeFillings = edgeFillings.slice();
    let error = false;

    for (let index in edgeFillings) {
      let edgeFilling = edgeFillings[index];

      try {
        let newEdgeFilling
        if (!edgeFilling.sent) {
          newEdgeFilling = await fetch('/edgefilling', 'POST', edgeFilling);
        }

        for (let i in edgeFilling.paths) {
          let path = edgeFilling.paths[i];
          path.paths_id = newEdgeFilling.paths_id;

          try {
            await fetch('/path', 'POST', path);
          } catch (err) {
            edgeFilling.sent = true;
            newEdgeFillings[index] = edgeFilling;
          }
        }

        newEdgeFillings.splice(newEdgeFillings.findIndex(e => e['id'] === edgeFilling['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['savedEdgeFillings'] = JSON.stringify(newEdgeFillings);
    getEdgeFillings(props.selectedConstructionSite.get('id'), error);
  }

  const sendUpdatedEdgeFillings = async () => {
    let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
    let newEdgeFillings = edgeFillings.slice();
    let error = false;

    for (let index in edgeFillings) {
      const edgeFilling = edgeFillings[index];

      try {
        const data = await fetch('/edgefilling/' + edgeFilling.id, 'GET');

        for (let i in edgeFilling.paths) {
          let path = edgeFilling.paths[i];
          path.paths_id = data.paths_id;

          if (path.id) {
            await fetch('/path/' + path.id, 'PATCH', path);
          }
          else {
            await fetch('/path', 'POST', path);
          }
        }

        newEdgeFillings.splice(newEdgeFillings.findIndex(newEdgeFilling => newEdgeFilling['id'] === edgeFilling['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['updatedEdgeFillings'] = JSON.stringify(newEdgeFillings);
    getEdgeFillings(props.selectedConstructionSite.get('id'), error);
  }

  const confirmRoadInfo = (callback) => {
    setCallback(() => callback);
    setConfirmed(true);
  }

  useEffect(() => {
    if (callback != null) {
      callback();
      setCallback(null);
    }
  }, [confirmed])

  const saveNewPathToLastEdgeFilling = (path) => {
    const lastEdgeFilling = props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      if (localStorage['updatedEdgeFillings'] == null) {
        localStorage['updatedEdgeFillings'] = JSON.stringify([]);
      }

      let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
      const existingIndex = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));

      if (existingIndex !== -1) {
        let paths = edgeFillings[existingIndex].paths;
        paths.push(path);
      }
      else {
        let paths = [];

        lastEdgeFilling.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            roadway: path.get('roadway'),
            direction: path.get('direction')
          });
        });

        paths.push(path);

        const edgeFilling = {
          id: lastEdgeFilling.get('id'),
          paths: paths
        }

        edgeFillings.push(edgeFilling);
      }

      localStorage['updatedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      const index = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));
      let paths = edgeFillings[index].paths;
      paths.push(path);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }

    props.addPathToLastEdgeFilling(path);
  }

  const endEdgeFilling = async () => {
    if (road_part === 0) {
      props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (!confirmed) {
      if (!showAccuracyFixer) {
        if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
          toggleAccuracyFixer(endEdgeFilling);
          return;
        }
      }
      else {
        setShowAccuracyFixer(false);
      }
    }

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart = road_part;
    const roadDistance = road_pole;

    try {
      if (!confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, endMacadam));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    setConfirmed(false);

    const currentEdgeFilling = creatingEdgeFilling;

    if (currentEdgeFilling != null) {
      setDisableSubmit(true);

      let edgeFilling = {
        construction_site_id: props.selectedConstructionSite.get('id'),
        pit: pit,
        truck_mass: parseFloat(truckMass),
        notice: notice,
        date: currentEdgeFilling.date,
        width: currentEdgeFilling.width,
        height: currentEdgeFilling.height,
        variety: currentEdgeFilling.variety,
        variety_weight: currentEdgeFilling.varietyWeight
      };

      if (props.selectedTruck != null) {
        edgeFilling.truck_id = props.selectedTruck.get('id');
        edgeFilling.truck = { register_number: props.selectedTruck.get('register_number') };
      }

      const direction = getAutomaticDirection(currentEdgeFilling.roadPart, currentEdgeFilling.roadDistance,
        road_part, road_pole);

      let path = {
        road: currentEdgeFilling.roadNumber,
        start_part: currentEdgeFilling.roadPart,
        start_distance: currentEdgeFilling.roadDistance,
        end_part: road_part,
        end_distance: road_pole,
        roadway: roadway,
        direction: direction,
        date: currentEdgeFilling.date,
      };

      let newEdgeFilling;

      try {
        newEdgeFilling = await fetch('/edgefilling', 'POST', edgeFilling);
      } catch (error) {
        saveEdgeFilling(edgeFilling, path);
        edgeFilling.date = edgeFilling.date + 'Z';
      }

      if (newEdgeFilling != null) {
        path.paths_id = newEdgeFilling.paths_id;

        try {
          await fetch('/path', 'POST', path);
        } catch (error) {
          saveNewPathToLastEdgeFilling(path);
        }
      }

      props.showNotice('Kuorma lopetettu', 'Ok');
      props.selectTruck(null);

      setNotice('');

      localStorage.removeItem('truck');
      localStorage.removeItem('notice');
      localStorage.removeItem('creatingEdgeFilling');

      try {
        await getEdgeFillings(props.selectedConstructionSite.get('id'), true);
      } catch (err) {
        edgeFilling.paths = [path];
        props.addEdgeFilling(edgeFilling);
      }

      setCreatingEdgeFilling(null);
    }
    else {
      setDisableSubmit(true);
      await makeEndForPath();
    }

    setDisableSubmit(false);
  }

  const makeEndForPath = async () => {
    const direction = getAutomaticDirection(lastPath.get('start_part'), lastPath.get('start_distance'),
      road_part, road_pole);
    console.log(direction);

    const path = {
      end_part: road_part,
      end_distance: road_pole,
      direction: direction
    };

    const lastEdgeFilling = props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      try {
        const data = await fetch('/path/' + lastPath.get('id'), 'PATCH', path);
        props.showNotice('Lopetus paalu tehty', 'Ok');
        props.updateLastPath(lastEdgeFilling.get('id'), data);
      } catch (error) {
        updateLocallyLastPath(path.end_part, path.end_distance);
        props.showNotice('Lopetus paalu tallennettu paikallisesti', 'Warning');
      }
    }
    else {
      updateLocallyLastPath(path.end_part, path.end_distance);
      props.showNotice('Lopetus paalu tehty', 'Ok');
    }
  }

  const updateLocallyLastPath = async (endPart, endDistance) => {
    let length = 0;

    if (endPart === lastPath.get('start_part')) {
      length = endDistance - lastPath.get('start_distance');
    }
    else {
      const url = ROAD_URL + '/muunna?tie=' + lastPath.get('road') +
        '&osa=' + lastPath.get('start_part') + '&etaisyys=' + lastPath.get('start_distance')
        + '&losa=' + endPart + '&let=' + endDistance;

      try {
        const data = await (await window.fetch(url).json());
        length = data['loppupiste']['pituus'];
      } catch (error) { }
    }

    const updatedPath = {
      id: lastPath.get('id'),
      road: lastPath.get('road'),
      start_part: lastPath.get('start_part'),
      start_distance: lastPath.get('start_distance'),
      end_part: endPart,
      end_distance: endDistance,
      length: length,
      roadway: lastPath.get('roadway'),
      direction: lastPath.get('direction'),
      date: lastPath.get('date')
    };

    const lastEdgeFilling = props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      if (localStorage['updatedEdgeFillings'] == null) {
        localStorage['updatedEdgeFillings'] = JSON.stringify([]);
      }

      let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
      const existingIndex = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));

      if (existingIndex !== -1) {
        let paths = edgeFillings[existingIndex].paths;
        paths[paths.length - 1] = updatedPath;
      }
      else {
        let paths = [];

        lastEdgeFilling.get('paths').forEach(path => {
          paths.push({
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            width: path.get('width'),
            area: path.get('area'),
            roadway: path.get('roadway'),
            direction: path.get('direction'),
            location_on_road: path.get('location_on_road'),
          });
        });

        paths[paths.length - 1] = updatedPath;

        const edgeFilling = {
          id: lastEdgeFilling.get('id'),
          paths: paths
        }

        edgeFillings.push(edgeFilling);
      }

      localStorage['updatedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      const index = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));
      let paths = edgeFillings[index].paths;
      paths[paths.length - 1] = updatedPath;
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }

    props.updateLastPath(lastEdgeFilling.get('id'), updatedPath);
  }

  const onSubmit = async () => {
    if (width === 0 || width == null) {
      props.showNotice('Leveys ei voi olla 0', 'Warning')
      return;
    }

    if (height === 0 || height == null) {
      props.showNotice('Korkeus ei voi olla 0', 'Warning')
      return;
    }

    if (variety === '' || variety == null) {
      props.showNotice('Lajiketta ei ole valittu', 'Warning')
      return;
    }

    if (varietyWeight === 0 || varietyWeight == null) {
      props.showNotice('Lajikkeen ominaispaino ei voi olla 0', 'Warning')
      return;
    }

    if (props.selectedConstructionSite == null) {
      props.showNotice('Kohdetta ei ole valittu', 'Warning')
      return;
    }

    if (road_part === 0 || road_part == null || road_part === '') {
      props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (road_pole == null || road_pole === '') {
      props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (!confirmed) {
      if (!showAccuracyFixer) {
        if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
          toggleAccuracyFixer(onSubmit);
          return;
        }
      }
      else {
        setShowAccuracyFixer(false);
      }
    }

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart = road_part;
    const roadDistance = road_pole;

    try {
      if (!confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, onSubmit));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    setConfirmed(false);

    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    const edgeFilling = {
      width: parseFloat(width),
      height: parseFloat(height),
      variety: variety,
      variety_weight: parseFloat(varietyWeight),
      constructionSiteId: props.selectedConstructionSite.get('id'),
      roadNumber: props.selectedConstructionSite.get('road_number'),
      roadPart: road_part,
      roadDistance: road_pole,
      date: date.toISOString().replace('Z', '')
    };

    setCreatingEdgeFilling(edgeFilling);

    localStorage['creatingEdgeFilling'] = JSON.stringify(edgeFilling);

    props.showNotice('Levitys aloitettu', 'Ok');
  }

  const getEdgeFillings = async (constructionSite, error = false) => {
    setLoadingEdgeFillings(true);

    const allPaths = await fetch('/paths/edgefilling/site/' + constructionSite);

    if (!error && typeof (Storage) !== 'undefined') {
      if (localStorage['savedEdgeFillings'] != null &&
        JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        await sendSavedEdgeFillings();
        return;
      }
      if (localStorage['updatedEdgeFillings'] != null &&
        JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        await sendUpdatedEdgeFillings();
        return;
      }
    }

    fetch('/edgefilling?site=' + constructionSite).then(async data => {
      for (let index in data) {
        let edgeFilling = data[index];

        if (edgeFilling.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === edgeFilling.paths_id);
            edgeFilling.paths = paths;

            let distance = 0;

            for (let path of paths) {
              distance += path.length;
            }

            const mass = (edgeFilling.width / 1000) * (edgeFilling.height / 1000) *
              edgeFilling.variety_weight * distance / 1000;

            edgeFilling.distance = distance;
            edgeFilling.mass = mass;
          } catch (error) {
            edgeFilling.paths = [];
          }
        }
        else {
          edgeFilling.paths = [];
        }
      }

      if (localStorage['savedEdgeFillings'] != null && JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        const savedEdgeFillings = JSON.parse(localStorage['savedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === props.selectedConstructionSite.get('id'));
        data = data.concat(savedEdgeFillings);
      }

      if (localStorage['updatedEdgeFillings'] != null && JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        const updatedEdgeFillings = JSON.parse(localStorage['updatedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === props.selectedConstructionSite.get('id'));
        updatedEdgeFillings.forEach(edgeFilling => {
          const index = data.findIndex(d => d.id === edgeFilling.id);
          if (index !== -1) data.splice(index, 1);
          data.push(edgeFilling);
        });
      }

      props.addEdgeFillings(data);
    }).catch(error => {
      console.log(error);
      let edgeFillings = [];

      if (localStorage['savedEdgeFillings'] != null && JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        const savedEdgeFillings = JSON.parse(localStorage['savedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === props.selectedConstructionSite.get('id'));
        edgeFillings = edgeFillings.concat(savedEdgeFillings);
      }

      if (localStorage['updatedEdgeFillings'] != null && JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        const updatedEdgeFillings = JSON.parse(localStorage['updatedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === props.selectedConstructionSite.get('id'));
        updatedEdgeFillings.forEach(edgeFilling => {
          const index = edgeFillings.findIndex(d => d.id === edgeFilling.id);
          if (index !== -1) edgeFillings.splice(index, 1);
          edgeFillings.push(edgeFilling);
        });
      }

      props.addEdgeFillings(edgeFillings);
    })
      .then(() => {
        setLoadingEdgeFillings(false);
      })
  }

  const goChangeEdgeFilling = (edgeFillingId) => {
    setChangingEdgeFilling(edgeFillingId);
  }

  const clearChangeEdgeFilling = () => {
    setChangingEdgeFilling(null);
  }

  const changeEdgeFilling = (edgeFilling) => {
    fetch('/edgefilling/' + edgeFilling.get('id'), 'PATCH', edgeFilling).then(async data => {
      props.showNotice('Reunantäyttö muokattu', 'Ok');

      if (data.paths_id) {
        data.paths = edgeFilling.get('paths');
      }

      props.changeEdgeFilling(data);
      clearChangeEdgeFilling();
    }).catch(error => {
      props.showMessage('Virhe', 'Reunantäytön muokkaus epäonnistui', 'Error');
    });
  }

  const changeLocalEdgeFilling = (edgeFilling) => {
    let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
    const index = edgeFillings.findIndex(e => e['id'] === changingMass.get('id'));
    edgeFillings[index] = edgeFilling;
    localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    props.showNotice('Reunantäyttö muokattu', 'Ok');
    props.changeEdgeFilling(edgeFilling);
    clearChangeEdgeFilling();
  }

  const confirmRemoveEdgeFilling = (edgeFilling) => {
    removingEdgeFilling = edgeFilling;
    props.showConfirm('Poistetaanko reunantäyttö?', removeEdgeFilling);
  }

  const removeEdgeFilling = () => {
    fetch('/edgefilling/' + removingEdgeFilling + '/', 'DELETE').then(data => {
      props.showNotice('Reunantäyttö poistettu', 'Ok')
      props.removeEdgeFilling(removingEdgeFilling);
    }).catch(error => {
      props.showMessage('Virhe', 'Reunantäytön poisto epäonnistui', 'Error');
    });
  }

  const removeLocalEdgeFilling = () => {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      edgeFillings = edgeFillings.filter(edgeFilling => edgeFilling['id'] !== removingEdgeFilling);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
      props.showNotice('Murske poistettu', 'Ok')
      props.removeMacadam(removingEdgeFilling);
    });
  }

  const confirmRemoveLocalEdgeFilling = (edgeFillingId) => {
    removeEdgeFilling = edgeFillingId;
    props.showConfirm('Poistetaanko reunantäyttö?', removeLocalEdgeFilling);
  }

  const confirmRemoveLastPath = (edgeFilling) => {
    removingPathByEdgeFilling = edgeFilling;
    props.showConfirm('Poistetaanko viimeisin täyttö kuormasta?', removeLastPath);
  }

  const removeLastPath = () => {
    fetch('/path/' + removingPathByEdgeFilling.get('paths').last().get('id'), 'DELETE').then(data => {
      props.showNotice('Täyttä poistettu', 'Ok')
      props.removeLastPath(removingPathByEdgeFilling.get('id'));
      setLastPath(props.edgeFillings.last().get('paths').last());
    }).catch(error => {
      console.log(error);
      props.showMessage('Virhe', 'Täytön poisto epäonnistui', 'Error');
    });
  }

  const getDailyData = (edgeFillings) => {
    let dailyCount = 0;
    let dailyDistance = 0;
    let dailyMass = 0;
    let wholeDistance = 0;
    let wholeMass = 0;

    let now = new Date();
    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = edgeFillings.size - 1; i >= 0; i--) {
      const edgeFilling = edgeFillings.get(i);
      const date = new Date(edgeFilling.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
        date <= now) {
        dailyCount++;
        dailyMass += edgeFilling.get('mass');
        dailyDistance += edgeFilling.get('distance');
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeEdgeFillingDate = new Date(edgeFillings.get(i - 1).get('date'));
        beforeEdgeFillingDate.setHours(beforeEdgeFillingDate.getHours());
        if (past6Hours >= beforeEdgeFillingDate) {
          newDay = true;
        }
      }

      wholeMass += edgeFilling.get('mass');
      wholeDistance += edgeFilling.get('distance');
    }

    setDailyCount(dailyCount);
    setDailyDistance(dailyDistance);
    setDailyMass(dailyMass);
    setWholeDistance(wholeDistance);
    setWholeMass(wholeMass);
  }

  const setView = (view) => {
    setViewState(view);
  }

  const goAddingPit = () => {
    const pit = prompt('Monttu:', '');
    if (pit == null || pit === '') return;
    else addPit(pit);;
  }

  const addPit = (pit) => {
    let pits = [];

    if (typeof (Storage) !== 'undefined') {
      if (localStorage['pits'] == null) {
        localStorage['pits'] = JSON.stringify([]);
      }
      pits = JSON.parse(localStorage['pits']);

      if (pits.length === 3) {
        pits.splice(2, 1);
      }

      pits.splice(0, 0, pit);
      localStorage['pits'] = JSON.stringify(pits);
    }
    else {
      pits = pits;
      pits.push(pit);
    }

    setPits(pits);
  }

  const toggleAccuracyFixer = (submit) => {
    setAccuracySubmit(submit);
    setShowAccuracyFixer(!showAccuracyFixer);
  }

  const getLastEdgeFillingData = (edgeFillings, creatingEdgeFilling) => {
    let lastPart = 0;
    let lastPole = 0;
    let lastWidth = null;
    let lastHeight = null;
    let lastVariety = null;
    let lastVarietyWeight = null;
    let lastPit = null;
    let lastDirection = null;
    let lastRoadway = null;
    let lastPath = null;

    const lastEdgeFilling = edgeFillings.last();

    if (creatingEdgeFilling) {
      lastPart = creatingEdgeFilling.roadPart;
      lastPole = creatingEdgeFilling.roadDistance;
    }
    else if (lastEdgeFilling != null) {
      if (lastEdgeFilling.get('paths').size !== 0) {
        const path = lastEdgeFilling.get('paths').last();

        if (path.get('end_part')) {
          lastPart = path.get('end_part');
          lastPole = path.get('end_distance');
        }
        else {
          lastPart = path.get('start_part')
          lastPole = path.get('start_distance');
        }

        lastWidth = lastEdgeFilling.get('width');
        lastDirection = path.get('direction');
        lastRoadway = path.get('roadway');

        lastPath = path;
      }

      lastHeight = lastEdgeFilling.get('height');
      lastVariety = lastEdgeFilling.get('variety');
      lastVarietyWeight = lastEdgeFilling.get('variety_weight');
      lastPit = lastEdgeFilling.get('pit');
    }

    setLastPart(lastPart);
    setLastPole(lastPole);
    setLastWidth(lastWidth);
    setLastHeight(lastHeight);
    setLastVariety(lastVariety);
    setLastVarietyWeight(lastVarietyWeight);
    setLastPit(lastPit);
    setLastDirection(lastDirection);
    setLastRoadway(lastRoadway);
    setLastPath(lastPath);
  }

  const getMapPaths = async (edgeFillings, site) => {
    setMapPaths([]);

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/edgefilling/site/' + site);
      } catch (err) { }
    }

    for (let i = 0; i < edgeFillings.size; i++) {
      const edgeFilling = edgeFillings.get(i);

      for (let p = 0; p < edgeFilling.get('paths').size; p++) {
        const path = edgeFilling.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
            [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
          [path.get('start_latitude'), path.get('start_longitude')]]
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
            path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }
          positions = await get2DirectionPath(positions);
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else if (props.yourLatitude != null) {
      zoom = 15;
      position = [props.yourLatitude, props.yourLongitude];
    }

    setMapPaths(paths),
      setMapZoom(zoom);
    setMapPosition(position);
  }

  const get2DirectionPath = async (path) => {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let url = ROAD_URL + '/muunna?y=' + converted.y + '&x=' + converted.x;
        let roadData = await (await window.fetch(url)).json();
        const roadNumber = roadData['tie'];
        const roadPart = roadData['osa'];
        let roadDistance = roadData['etaisyys'];
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        url = ROAD_URL + '/muunna?tie=' + roadNumber + '&osa=' + roadPart + '&etaisyys=' + roadDistance;
        roadData = await (await window.fetch(url)).json();
        const x = roadData['alkupiste']['tieosoitteet'][0]['point']['x'];
        const y = roadData['alkupiste']['tieosoitteet'][0]['point']['y'];
        const anotherCoordinates = toWGS84(y, x);

        const angle = getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
          path[0][0], path[0][1]);

        const newCoordinate = getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch (error) {
        return path;
      }
    }

    return newPath;
  }

  const getOffSetAngle = (lat1, lon1, lat2, lon2) => {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
      Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  const getNewCoordinatesByAngle = (angle, latitude, longitude) => {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
      Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  const newPath = async () => {
    if (props.selectedConstructionSite == null) {
      props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (lastPath == null) {
      props.showNotice('Aikasempaa kuormaa ei ole', 'Warning');
      return;
    }

    if (width === "0" || width === "" || width === 0) {
      props.showNotice('Tien leveyttä ei ole annettu', 'Warning');
      return;
    }

    if (road_part === 0 || road_part === '') {
      props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (road_pole === null || road_pole === '') {
      props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    if (!confirmed) {
      if (!showAccuracyFixer) {
        if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
          toggleAccuracyFixer(newPath);
          return;
        }
      }
      else {
        setShowAccuracyFixer(false);
      }
    }

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart = road_part;
    const roadDistance = road_pole;

    try {
      if (!confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, newPath));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    setConfirmed(false);
    setDisableNewPath(true);

    if (!lastPath.get('end_part')) {
      await makeEndForPath();
    }

    let date = new Date();
    date.setHours(date.getHours());

    let path = {
      paths_id: lastPath.get('paths_id'),
      road: roadNumber,
      start_part: roadPart,
      start_distance: roadDistance,
      roadway: roadway,
      direction: direction,
      date: date.toISOString().replace('Z', ''),
      width: width,
    };

    fetch('/path', 'POST', path).then(async data => {
      props.showNotice('Uusi aloitus tehty', 'Ok');
      props.addPathToLastEdgeFilling(data);
    }).catch(error => {
      saveNewPathToLastEdgeFilling(path);
      props.showNotice('Uusi aloitus tallenettu paikallisesti', 'Warning');
    }).then(() => {
      setDisableNewPath(false);
    });
  }

  const getPaths = async (id) => {
    const paths = await fetch('/paths/' + id);
    return paths;
  }

  const getAutomaticDirection = (lastRoadPart, lastRoadDistance, roadPart, roadDistance) => {
    let direction;

    console.log(lastRoadDistance)
    console.log(roadDistance)

    if (lastRoadPart < roadPart ||
      (lastRoadPart === roadPart && lastRoadDistance < roadDistance)) {
      direction = 1;
    }
    else {
      direction = 2;
    }

    setDirection(direction);
    return direction;
  }

  return (
    <div>
      <div className="container">
        <h1>Reunantäyttö</h1>
        <div className='button-area'>
          <button className='button-view' onClick={setView.bind(this, 0)}
            disabled={viewState === 0}>
            Perustiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 1)}
            disabled={viewState === 1}>
            Levitystiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 2)}
            disabled={viewState === 2}>
            Seurantatiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 3)}
            disabled={viewState === 3}>
            Kartta
          </button>
        </div>
        <View
          view={viewState} changeState={changeState} height={height}
          width={width} varietyWeight={varietyWeight}
          variety={variety} pit={pit} pits={pits}
          roadway={roadway} direction={direction}
          startRoadPart={road_part}
          startRoadPole={road_pole}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          newEdgeFilling={onSubmit} useLocation={useLocation}
          mass={truckMass}
          notice={notice}
          dailyMass={dailyMass} dailyDistance={dailyDistance}
          wholeDistance={wholeDistance} wholeMass={wholeMass}
          dailyCount={dailyCount}
          edgeFillings={props.edgeFillings} selectedConstructionSite={props.selectedConstructionSite}
          loading={loadingEdgeFillings} confirmRemoveEdgeFilling={confirmRemoveEdgeFilling}
          confirmRemoveLocalEdgeFilling={confirmRemoveLocalEdgeFilling}
          removeLastPath={confirmRemoveLastPath}
          location_road_number={location_road_number} location_road_part={location_road_part}
          location_road_pole={location_road_pole} accuracy={accuracy}
          locationTime={locationTime} latitude={latitude} longitude={longitude}
          mapPaths={mapPaths} mapZoom={mapZoom} mapPosition={mapPosition}
          showMessage={props.showMessage} changeEdgeFilling={goChangeEdgeFilling}
          goAddingPit={goAddingPit} autoGPS={autoGPS}
          endEdgeFilling={endEdgeFilling}
          creatingEdgeFilling={props.selectedConstructionSite && ((creatingEdgeFilling &&
            creatingEdgeFilling.constructionSiteId === props.selectedConstructionSite.get('id'))
            || (lastPath && lastPath.get('end_part') == null))}
          disableSubmit={disableSubmit}
          lastPart={lastPart}
          lastPole={lastPole}
          lastHeight={lastHeight}
          lastWidth={lastWidth}
          lastVariety={lastVariety}
          lastVarietyWeight={lastVarietyWeight}
          lastPit={lastPit}
          lastDirection={lastDirection}
          lastRoadway={lastRoadway}
          paths={paths}
          newPath={newPath}
          disableNewPath={disableNewPath}
          organizationId={props.organizationId}
          store={props.store}
        />
        <ChangeEdgeFillingView edgeFilling={changingEdgeFilling} clear={clearChangeEdgeFilling}
          changeEdgeFilling={changeEdgeFilling} changeLocalEdgeFilling={changeLocalEdgeFilling}
          goAddingPit={goAddingPit} pits={pits} />
        <AccuracyFixer show={showAccuracyFixer} toggle={toggleAccuracyFixer}
          submit={!creatingEdgeFilling ? onSubmit : endEdgeFilling} accuracy={accuracy} />
        <div id='top-info'>
          {creatingEdgeFilling ?
            <div>
              <span className='distance-info'>
                Aloitus: <strong>{creatingEdgeFilling.roadPart + ' / ' +
                  creatingEdgeFilling.roadDistance}</strong>
              </span>
              {autoGPS ?
                <div>
                  <span className='distance-info'>
                    Matka: <strong>{traveledDistance} m</strong>
                  </span>
                  <span className='distance-info'>
                    Massa: <strong>{currentMassUsage} tonnia</strong>
                  </span>
                </div>
                : null
              }
            </div>
            :
            null
          }
          <span className='distance-info'>
            Korkeus: <strong>{height} mm</strong>
          </span>
          <span className='distance-info'>
            Leveys: <strong>{width} mm</strong>
          </span>
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({
  edgeFillings: state.edgeFilling.get('edgeFillings'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), {
  addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings,
  changeEdgeFilling, updateLastPath, addPathToLastEdgeFilling, removeLastPath,
  showNotice, showMessage, showConfirm, selectTruck
})(EdgeFillingNew);
